/*----------------------------------------*/
/*  35 - Pages - Compare
/*----------------------------------------*/
.compare-area {
	padding: 100px 0;
}
.compare-table {
	.table {
		thead {
			th {
				border-width: 1px;
				padding: 15px 10px;
			}
		}
		tbody {
			tr {
				@extend %basetransition;
				&:hover {
					background-color: rgba(0, 0, 0, .02);
				}
				td,
				th {
					vertical-align: middle;
					text-align: center;
				}
				th {
					min-width: 200px;
				}
				td {
					min-width: 250px;
					.compare-pdoduct-image {
						max-width: 200px;
						display: inline-block;
						padding: 20px 0;
						a {
							display: inline-block;
						}
						.quicky-btn {
							margin-top: 30px;
							&:hover {
								color: $white-color !important;
							}
						}
					}
					.compare-product-name {
						font-size: 14px;
						margin-bottom: 0;
						a {
							-webkit-transition-property: color;
							-o-transition-property: color;
							transition-property: color;
						}
					}
					.rating-box {
						> ul {
							> li {
								display: inline-block;
							}
						}
					}
				}
			}
		}
	}
	.table-hover>tbody>tr:hover {
		--bs-table-accent-bg: transparent;
	}
}
