/*----------------------------------------*/
/*  19 - Components - Animation
/*----------------------------------------*/
/* Animation Style One */
.slick-active {
	& .animation-style-01 {
		.slide-content {
			> h2 {
				animation-name: fadeInUp;
				animation-delay: 2s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
				
			}
			> p {
				animation-name: fadeInUp;
				animation-delay: 2.5s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
			}
			> .slide-btn {
				animation-name: fadeInUp;
				animation-delay: 3s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
			}
		}
		.slider-img{
			img{
				animation-name: fadeInRight;
				animation-delay: 3.5s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
			}
		}
	}
	& .animation-style-02 {
		.slide-content {
			> h2 {
				animation-name: fadeInLeft;
				animation-delay: 2s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
				
			}
			> p {
				animation-name: fadeInLeft;
				animation-delay: 2.5s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
			}
			> .slide-btn {
				animation-name: fadeInLeft;
				animation-delay: 3s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
			}
		}
		.slider-img{
			img{
				animation-name: fadeInUp;
				animation-delay: 3.5s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
			}
		}
	}
	& .animation-style-03 {
		.slide-content {
			> h2 {
				animation-name: fadeInRight;
				animation-delay: 2s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
				
			}
			> p {
				animation-name: fadeInRight;
				animation-delay: 2.5s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
			}
			> .slide-btn {
				animation-name: fadeInRight;
				animation-delay: 3s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
			}
		}
		.slider-img{
			img{
				animation-name: fadeInLeft;
				animation-delay: 3.5s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
			}
		}
	}
	& .animation-style-04 {
		.left-side_img{
			img{
				animation-name: fadeInLeft;
				animation-delay: 3.5s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
			}
		}
		.slide-content {
			> h2 {
				animation-name: fadeInUp;
				animation-delay: 2s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
				
			}
			> p {
				animation-name: fadeInUp;
				animation-delay: 2.5s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
			}
			> .slide-btn {
				animation-name: fadeInUp;
				animation-delay: 3s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
			}
		}
		.right-side_img{
			img{
				animation-name: fadeInUp;
				animation-delay: 4s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
			}
		}
	}
}

/* ---Slider Progress Bar--- */
.slider-progress {
	background: rgba(0, 0, 0, .3);
	-webkit-animation: initial;
	animation: initial;
	height: 5px;
	left: 0;
	opacity: 0.8;
	position: absolute;
	top: 0;
	z-index: 4;
}

.slick-current {
    .slider-progress {
        -webkit-animation: timebar 8s ease-in-out 0s 1 backwards;
        animation: timebar 8s ease-in-out 0s 1 backwards;
    }
}

// Keyframes
@-webkit-keyframes timebar {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}
@keyframes timebar {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}

// Kyframes

/*Top Up Keyframes*/
@-webkit-keyframes quickySlideInUp {
	0% {
		-webkit-transform: translateY(250px);
		transform: translateY(250px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes quickySlideInUp {
	0% {
		-webkit-transform: translateY(250px);
		transform: translateY(250px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}
/*Top Down Keyframes*/

 @-webkit-keyframes quickySlideInDown {
	0% {
	  -webkit-transform: translateY(0);
			  transform: translateY(0);
	}
	100% {
	  -webkit-transform: translateY(100px);
			  transform: translateY(100px);
	}
  }
  @keyframes quickySlideInDown {
	0% {
	  -webkit-transform: translateY(0);
			  transform: translateY(0);
	}
	100% {
	  -webkit-transform: translateY(100px);
			  transform: translateY(100px);
	}
  }

  .quickySlideInDown {
	-webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
  
// Begin Slide In Right Kyframes
.slide-in-right {
	-webkit-animation: slide-in-right 4s linear infinite alternate both;
	animation: slide-in-right 4s linear infinite alternate both;
}
/* ----------------------------------------------
 * Generated by Animista on 2019-1-7 17:0:13
 * w: https://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-right {
	0% {
		-webkit-transform: translateX(340px);
		transform: translateX(340px);
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}
@keyframes slide-in-right {
	0% {
		-webkit-transform: translateX(340px);
		transform: translateX(340px);
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}
// Slide In Right Kyframes End Here
